import React, { FC, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import ModuleLoop from '@/containers/ModulesLoop'
import Grid from '@/atoms/Grid'
import Box from '@material-ui/core/Box'
import { Maybe, SanityStore } from 'web/graphql-types'
import StoresWrapper from '@/containers/StoresWrapper'
import { Typography } from '@material-ui/core'
import { NavigationContext } from '@/contexts/NavigationContext'
import { StoresContext } from '@/contexts/StoresContext'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Head from '@/containers/Head'

const StyledBox = styled(Box)`
  @media screen and (max-width: 1024px) {
    width: 72rem;
    max-width: 100vw;
  }
`

const StyledTitleBox = styled(Box)`
  @media screen and (max-width: 1024px) {
    width: calc(100vw - 16rem);
    max-width: 100vw;
  }
`

const manipulateOpeningHours = (
  openingHours: Maybe<Maybe<string>[]> | undefined,
  edit_opening_hours?: boolean
): (string | undefined)[] | undefined => {
  if (!openingHours) return

  // remove sunday if opening hours from google
  const manipulated = !edit_opening_hours ? openingHours.slice(0, -1) : openingHours

  return manipulated
    .map(hours => hours?.replace(hours.substr(2, hours.search(':') - 2), ''))
    .map(hours => hours?.replace(/ Uhr/g, ''))
    .map(hours => hours?.replace(/:00/g, ''))
}

export type GoogleLocation = {
  lat: number
  lng: number
}

export const onlineStoreLocation = {
  lat: 47.340056,
  lng: 8.553444
}

interface StoreProps {
  data: {
    store: SanityStore
  }
}

const Store: FC<StoreProps> = props => {
  const {
    title,
    meta,
    place_details,
    contentModules,
    location,
    onlineStore
  } = props.data.store
  const currentStore = props.data.store
  const { setFocusedStore, setCurrentStore, setCenter, setZoom } = useContext(
    StoresContext
  )
  const { setRoute, setBackButton } = useContext(NavigationContext)

  useEffect(() => {
    setBackButton(true)
    setRoute('/stores')
  }, [])

  useEffect(() => {
    setCurrentStore(currentStore.id)
    setFocusedStore(currentStore.id)
    setCenter(
      onlineStore
        ? onlineStoreLocation
        : { lat: location?.lat || 47.375, lng: location?.lng || 8.53 }
    )
    setZoom(15)
    return () => {
      setCurrentStore(null)
      setFocusedStore(null)
    }
  }, [])

  return (
    <Grid container spacing={0}>
      <Head title={title} meta={meta} />
      <Grid item xs={12}>
        <StyledTitleBox
          m={{ xs: '0', sm: 0 }}
          pt={5}
          pl={{ xs: 3, sm: 6 }}
          pb={3}
          width={'calc(50vw - 20rem)'}
        >
          <Typography variant="h2" component="h1">
            {title}
          </Typography>
        </StyledTitleBox>
      </Grid>

      <Grid item xs={12}>
        <Box mr={{xs: '10rem', sm: 0}} px={{ xs: 'auto', sm: 6 }}>
          <ModuleLoop
            modules={contentModules?.modules?.slice(0, 1)}
            siteType={'store'}
          />
        </Box>
      </Grid>
      {!onlineStore && (
        <Box flexGrow={0} maxWidth="100%" flexBasis="100%" mr={{ xs: '10rem', sm: 0 }}>
          <StyledBox pb={1} mt={0.5} px={{ xs: 3, sm: 6 }} mx={'auto'}>
            <Typography>
              {manipulateOpeningHours(place_details?.opening_hours, place_details?.edit_opening_hours)?.map(
                text => (
                  <>
                    {text} <br />
                  </>
                )
              )}
            </Typography>
          </StyledBox>
        </Box>
      )}
      <Grid item xs={12}>
        <Box px={{ xs: 0, sm: 6 }}>
          <ModuleLoop
            modules={contentModules?.modules?.slice(1)}
            siteType={'store'}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

Store.StoresWrapper = StoresWrapper
Store.isStoreIndex = false

export default Store

export const query = graphql`
  query($id: String!) {
    store: sanityStore(id: { eq: $id }) {
      id
      title
      meta {
        ...metaData
        metaDescription
      }
      place_details {
        edit_opening_hours
        google_place_id
        formatted_address
        formatted_phone_number
        opening_hours
        website
      }
      onlineStore
      location {
        lat
        lng
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
